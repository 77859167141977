<template>
    <div>
      <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
        <div class="col-span-1 flex items-center flex-wrap">
          <h2 class="text-2xl font-bold mr-3">Awaiting Approval Cash Transactions</h2>
        </div>
        <div class="col-span-1">
          <div class="relative">
            <search-form
              v-model="query"
              placeholder="Search for user's name, status, etc."
              @submit="(event) => $refs.table.loadAjaxData()"
            />
          </div>
        </div>
      </div>
      <div class="border border-solid border-blue-200">
        <datatable
          :index="true"
          :url="`${this.$baseurl}/admin/personal/cash/awaiting-approval`"
          :ajax="true"
          :ajax-pagination="true"
          :on-click="goToUser"
          :fillable="true"
          :columns="transactions.columns"
          :actions="transactions.actions"
          ref="table"
        />
      </div>
    </div>
  </template>
  <script>
  import moment from 'moment';
  export default {
    data() {
      return {
        query: '',
        transactions: this.$options.resource([], {
          columns: [
            {
              name: 'user_id',
              th: 'User ID'
            },
            {
              name: 'full_name',
              th: 'User',
            },
            {
              name: 'account_name',
              th: 'Account Name',
            },
            {
              name: 'account_no',
              th: 'Account Number',
            },
            {
              name: 'bank_name',
              th: 'Bank Name',
            },
            {
              name: 'amount',
              th: 'Amount Attempting To Transfer',
            },
            {
              name: 'created_at',
              th: 'Created At',
              render: (_, created_at) =>
                moment(created_at).format("MMM Do YYYY, h:mm:ss a")
            }
          ],
          actions: [
              {
                text: 'approve cash transfer',
                class: 'bg-green-600 text-white rounded-sm px-4 py-2 hover:bg-green-500',
                action: this.approveCashTransfer,
                show: () => this.canDo('credpal:can_ratify_awaiting_cash_transfers')
              },
              {
                text: 'reject cash transfer',
                class: 'bg-red-600 text-white rounded-sm px-4 py-2 hover:bg-red-500',
                action: this.rejectCashTransfer,
                show: () => this.canDo('credpal:can_ratify_awaiting_cash_transfers')
              }
          ]
        }),
      };
    },
    methods: {
      reloadTable() {
        this.$refs.table.loadAjaxData();
      },
      approveCashTransfer(cashTransfer) {
        this.sendRequest('admin.users.cash.approveAwaitingCashTransfer', cashTransfer?.id, {
          success: () => { 
            this.$success({
              title: 'Operation Successful',
              body: 'Transfer Approved',
              button: 'Okay',
            });
            this.reloadTable();
          },
          error: () => {
            this.$error({
              title: 'Operation Failed',
              body: 'Transfer Approval Fail',
              button: 'Okay',
            });
          },
        });
      },
      rejectCashTransfer(cashTransfer) {
        this.sendRequest('admin.users.cash.rejectAwaitingCashTransfer', cashTransfer?.id, {
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: 'Transfer Rejection',
              button: 'Okay',
            });
            this.reloadTable();
          },
          error: () => {
            this.$error({
              title: 'Operation Failed',
              body: 'Transfer Rejection Fail',
              button: 'Okay',
            });
          },
        });
      },
      goToUser({ user_id, user }) {
        return this.$router.push({
          name: 'staff-view',
          params: {
            staffId: user_id?.id || user?.id,
          },
        });
      },
    },
  };
  </script>
  